<template>
    <div class="h-screen flex flex-col">
        <div class="w-full border-b border-gray-400 p-3">
            <NuxtLink to="/">
                <button><nuxt-img class="h-12" src="/boardable-forms.svg"></nuxt-img></button>
            </NuxtLink>
        </div>
        <slot />
    </div>
</template>
